/* ----- Core Generator ------- */
var Gen_Func = {
    // Password Generator        
    password_generator: function() {
        // Form Input Variable
        var typingTimerPassLength;
        var doneTypingIntervalPassLength = 100;
        var $passwordLength = $('input[name="password_length"]');

        // Declare Generator Settings
        var uppercase = "QWERTYUIOPASDFGHJKLZXCVBNM";
        var lowercase = "qwertyuiopasdfghjklzxcvbnm";
        var number = "1234567890";
        var symbol = "!@#$%^&*";

        var joinLetter = '';
        var passwordLength = 10;
        var password = '';

        var isUppercase = true;
        var isLowercase = false;
        var isNumber = true;
        var isSymbol = false;

        // Default Password Generator
        joinLetter = uppercase+number;
        for (var i = 1; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * joinLetter.length);
            password += joinLetter.substring(randomNumber, randomNumber +1);
        }
        $('input[name="result"]').val(password);

        // 
        // Password Length Typing
            // on keyup, start the countdown
            $passwordLength.on('keyup', function () {
                clearTimeout(typingTimerPassLength);
                typingTimerPassLength = setTimeout(doneTypingPassLength, doneTypingIntervalPassLength);
            });

        //user is "finished typing," do something
        function doneTypingPassLength () { 
            if($passwordLength.val() != '')
            {
                passwordLength = $passwordLength.val();
                generatePassword();
            }
            else
            {
                passwordLength = 10;
                generatePassword();
            }
        }

        // Lowercase Checkbox Function
        $(document).on('change', 'input[name="check_lowercase"]', function(){
            if($(this).is(':checked'))
            {
                isLowercase = true;
                generatePassword();
            }
            else
            {
                isLowercase = false;
                generatePassword();
            }
        });
        // Uppercase Checkbox Function
        $(document).on('change', 'input[name="check_uppercase"]', function(){
            if($(this).is(':checked'))
            {
                isUppercase = true;
                generatePassword();
            }
            else
            {
                isUppercase = false;
                generatePassword();
            }
        });
        // Number Checkbox Function
        $(document).on('change', 'input[name="check_number"]', function(){
            if($(this).is(':checked'))
            {
                isNumber = true;
                generatePassword();
            }
            else
            {
                isNumber = false;
                generatePassword();
            }
        });
        // Symbol Checkbox Function
        $(document).on('change', 'input[name="check_symbol"]', function(){
            if($(this).is(':checked'))
            {
                isSymbol = true;
                generatePassword();
            }
            else
            {
                isSymbol = false;
                generatePassword();
            }
        })

        // Button Generate Clicked
        $(document).on('click', '.btn-generate', function(){
            generatePassword();
        });
        // Button Copy Clicked
        $(document).on('click', '.btn-copy', function(){
            // getting text field
            var copyText = document.getElementById("inpt-result");  
            // creating textarea of html
            var input = document.createElement("textarea");
            //adding p tag text to textarea 
            input.value = copyText.value;
            document.body.appendChild(input);
            input.select();
            document.execCommand("Copy");
            // removing textarea after copy
            input.remove();

            alert('Password Copied!');
        });

        // Function for generate password
        function generatePassword ()
        {
            joinLetter = '';
            password = '';

            // 
            if(isUppercase == true)
                joinLetter+=uppercase;
            if(isLowercase == true)
                joinLetter+=lowercase;
            if(isNumber == true)
                joinLetter+=number;
            if(isSymbol == true)
                joinLetter+=symbol;                
            
            for (var i = 1; i <= passwordLength; i++) {
                var randomNumber = Math.floor(Math.random() * joinLetter.length);
                password += joinLetter.substring(randomNumber, randomNumber +1);
            }
            $('input[name="result"]').val(password);
        }
    },
    // UUID Generator
        // V1
        uuid_v1_generator: function() {
            // Default load UUID
            $.ajax({
                url: '/generator/uuid/v1',
                type: 'GET',
                dataType: 'json',
                success: function(data) {
                    $('input[name="result"]').val(data.result);
                },
                error: function (xhr, ajaxOptions, thrownError) {
                }
            });        

            // Button Generate Clicked
            $(document).on('click', '.btn-generate', function(){
                refreshUUID();
            });
            // Button Copy Clicked
            $(document).on('click', '.btn-copy', function(){
                // getting text field
                var copyText = document.getElementById("inpt-result");  
                // creating textarea of html
                var input = document.createElement("textarea");
                //adding p tag text to textarea 
                input.value = copyText.value;
                document.body.appendChild(input);
                input.select();
                document.execCommand("Copy");
                // removing textarea after copy
                input.remove();

                alert('UUID Copied!');
            });

            // Function for refresh UUID
            function refreshUUID ()
            {
                $.ajax({
                    url: '/generator/uuid/v1',
                    type: 'GET',
                    dataType: 'json',
                    success: function(data) {
                        $('input[name="result"]').val(data.result);
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                    }
                });  
            }
        },
        // V4
        uuid_v4_generator: function() {
            // Default load UUID
            $.ajax({
                url: '/generator/uuid/v4',
                type: 'GET',
                dataType: 'json',
                success: function(data) {
                    $('input[name="result"]').val(data.result);
                },
                error: function (xhr, ajaxOptions, thrownError) {
                }
            });        

            // Button Generate Clicked
            $(document).on('click', '.btn-generate', function(){
                refreshUUID();
            });
            // Button Copy Clicked
            $(document).on('click', '.btn-copy', function(){
                // getting text field
                var copyText = document.getElementById("inpt-result");  
                // creating textarea of html
                var input = document.createElement("textarea");
                //adding p tag text to textarea 
                input.value = copyText.value;
                document.body.appendChild(input);
                input.select();
                document.execCommand("Copy");
                // removing textarea after copy
                input.remove();

                alert('UUID Copied!');
            });

            // Function for refresh UUID
            function refreshUUID ()
            {
                $.ajax({
                    url: '/generator/uuid/v4',
                    type: 'GET',
                    dataType: 'json',
                    success: function(data) {
                        $('input[name="result"]').val(data.result);
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                    }
                });  
            }
        }
}